import { useNetwork } from "wagmi";

export const xAccessCode = {
   oneOf: 0,
   master: 1,
   admin:  2,
   chief:  3
};

export const chains = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  KOVAN: 42,
  SEPOLIA: 42161,
  ARBITRUM: 11155111,
  SQRS: 11111111111
};

export const supportedChainIds = [
  chains.MAINNET,
  chains.SEPOLIA,
  chains.ARBITRUM,
  chains.SQRS
];

export const pollingInterval = 12000;

export const networkContextName = 'NU';

export const supportedNetworkURLs = {
  1: 'https://mainnet.infura.io/v3/4bd1467d1bb14c04897dc22d72bdf57d'
};

export const xTrader = [
  '0x08564040a7d238A310c9b0995923Cb860892EF59',
  '0x99091854E3bD56dA7998887Fd4361dbc229Dd818'
];

export const rpcURLs = {
  [chains.MAINNET]:  "https://eth-mainnet.alchemyapi.io/v2/GUEohLZ1A5Sv0e5mnfOu0lvF6kwfgkyc",
  [chains.ROPSTEN]:  "https://eth-mainnet.alchemyapi.io/v2/GUEohLZ1A5Sv0e5mnfOu0lvF6kwfgkyc",
  [chains.RINKEBY]:  "https://eth-mainnet.alchemyapi.io/v2/GUEohLZ1A5Sv0e5mnfOu0lvF6kwfgkyc",
  [chains.KOVAN]:    "https://eth-mainnet.alchemyapi.io/v2/GUEohLZ1A5Sv0e5mnfOu0lvF6kwfgkyc",
  [chains.SEPOLIA]:  "https://eth-mainnet.alchemyapi.io/v2/GUEohLZ1A5Sv0e5mnfOu0lvF6kwfgkyc",
  [chains.ARBITRUM]: "https://eth-mainnet.alchemyapi.io/v2/GUEohLZ1A5Sv0e5mnfOu0lvF6kwfgkyc",
};


export const xNullAddress =  '0x0000000000000000000000000000000000000000';
export const xUSDTAddress = {
  1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',  // main
  5: '0x5375EC7492374e2B67BA295506eF38D69C8f992b',  // goerli
  42161: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', // arbitrum
  11155111: '0x074Db31516935B8e0499D6E4e779b68E79243658' //sepolia
}
export const xUSDCAddress = {
  1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',  // main
  11155111: '0x074Db31516935B8e0499D6E4e779b68E79243658' //sepolia
}

export const xUSDZAddress = {
  1: '0xE4b70F9DdBb286f3cFA836E0fD970479f3193252',
  5: '0x0DaEBb0941E09374B45675374aa24023B461360E',
11155111: '0xBDA34170b69AC75ACfe9E17fc646D19b386c5504'
}; //main chain only

export const xOpCode = {
   block: 0x111,
   unBlock: 0x222,
   check: 0x333,
   protect: 0x666,
   txBlock: 0x777,
   txPay: 0x123
};


export const xBalance = {
  isNoticed: 0,
  protected: 1,
  erc20: 2,
  blocked: 3,              
  claimable: 4,
  locked: 5,
  lastBlockingTime: 6,    
  summarized: 7,
  summarizedAPY: 8,
  summarizedTotal: 9,
  summarizedTotalAPY: 10,
  rn: 11,
  digSum: 12,
  digQual: 13,
  digQualChildCount: 14,
  digSumChild: 15,
  provision: 16,
  sale: 17,
  digSumCapped: 18,
  digSumHodled: 19,
  digFactor: 20,
  payCount: 21,
  lastEarningTime: 22
}

export const xBalance_rock2 = {
  isNoticed: 0,
  protected: 1,
  erc20: 2,
  blocked: 3,
  summarized: 4,
  summarizedAPY: 5,
  summarizedTotal: 6,
  summarizedTotalAPY: 7,
  rn: 8,
  lastBlockingTime: 9,
  digSum: 10,
  digQual: 11,
  digQualChildCount: 12,
  digSumChild: 13,
  authorized: 14,
  sale: 15,
  digSumCapped: 16,
  digSumHodled: 17,
  digFactor: 18,
  payCount: 19,
  lastEarningTime: 20,
  claimable: 21
}

export const xBalance_rock1 = {
  erc20: 0,
  blocked: 1,
  summarized: 2,
  summarizedAPY: 3,
  summarizedTotal: 4,
  summarizedTotalAPY: 5,
  digSum: 6,
  authorized: 7,
  sale: 8,
  digQual: 9,
  digQualChildCount: 10,
  digSumChild: 11,
  lastBlockingTime: 12,
  protected: 13,
  rn: 14,
  isNoticed: 15
};


export const xTotal = {
 supplyERC20: 0,
 supplyBlocked: 1,
 summarized: 2,
 totalSummarizedAPY: 3,
 totalDigged: 4,
 capped: 5,
 hodled: 6
};
