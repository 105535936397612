import React, { useState } from "react";
import { QRCode } from "react-qrcode-logo";

import classes from "./Profile.module.css";
import copyicon from "../assets/copymini.png";

//import PreSale from "./PreSale";

import { z } from '../Z';
import { xBalance } from "../X";

import ConnectW from "./Connect";

const Profile = (props) => {
  const [copiedInfo, setCopiedInfo] = useState(false);
  //  const [copiedInfo2, setCopiedInfo2] = useState(false);

  let uriInviteDotted = props.uriInvite.substring(0, 20) + "...";
  let uriInviteDeepLink = [
    "https://metamask.app.link/dapp",
    props.uriInvite.replace("https://", ""),
  ].join("/");

  const copyAddress = (event) => {
    navigator.clipboard.writeText(props.uriInvite);

    setCopiedInfo(true);

    setTimeout(() => {
      setCopiedInfo(false);
    }, 3000);
  };
  /*
    const copyAddress2 = (event) => {
       navigator.clipboard.writeText(props.contract._address);

       setCopiedInfo2(true);

       setTimeout(() => {
         setCopiedInfo2(false);
      }, 3000);
    };
*/

  /*
    const closeBox = (event) => {
       setCopiedInfo(false);
    };

    <li> total: <b>{props.balances ? parseInt(props.balances[xBalance.erc20]) + parseInt(props.balances[xBalance.blocked]): "n/a"}</b> ROCKs</li>
    <li> rewarded: <b>{props.balances ? props.balances[2] : "n/a" }</b> Pebbles</li>

*/

  /*
    const datum = (s) => {
           let dt = new Date(s * 1000);
           return (
                    dt.getDate()+
                "/"+(dt.getMonth()+1)+
                "/"+dt.getFullYear()+
                " "+dt.getHours()+
                ":"+dt.getMinutes()+
                ":"+dt.getSeconds()
              );
      }
*/

  const rc = (p, d, c) => {
    let a = p / 10 ** d;
    let i = Math.floor(a);
    let f = a - i;
    return i.toLocaleString("en-US") + f.toFixed(c).toString().substring(1);
  };
  const rcRock = (p) => {
    return rc(p, props.info.decimals, 3).replace(/0+$/, "").replace(/\.$/, "");
  };

  const rcPrice = (p) => {
    return rc(p, props.priceDecimals, 2);
  };
  const rcPrice6 = (p) => {
    return rc(p, props.provDecimals, 2);
  };

  const rcPriceX = (p) => {
    return rc(p, props.rpriceDecimals, 8).replace(/0+$/, "").replace(/\.$/, "");
  };

  //      <div  style={{  position: "relative", left: "0%"}}><b>{props.balances ? rcPriceX(props.balances[xBalance.summarizedTotalAPY]) : "n/a" }</b> {props.rpriceSymbol}<br/><b style={{"font-size": "0.5em"}}>EARNED</b></div>
  /*
      <PreSale
            preSaleDate={props.preSaleDate}
            preSaleOffer={props.preSaleOffer}
            preSaleTotal={props.preSaleTotal}
            preSaleLimit={props.preSaleLimit}
    />
*/

  return (
    <div className={classes.profile}>
      <br></br>
      {props.isOnline() ? (
        <div className={classes.logo} width="200">
          <div className={classes.xx}>
            <div style={{ position: "relative", top: "0em" }}>
              <b>
                {props.balances
                  ? rcRock(
                      props.balances[xBalance.erc20] * 1 +
                        props.balances[xBalance.blocked] * 1,
                    )
                  : "n/a"}
              </b>{" "}
              {z.title}
              <br />
              <b style={{ "font-size": "0.5em" }}> OWNED</b>
            </div>
            <div style={{ width: "50%" }}></div>
            <div style={{ position: "relative", top: "0em" }}>
              <b>
                {props.balances
                  ? rcPrice6(props.balances[xBalance.authorized])
                  : "n/a"}
              </b>{" "}
              USD
              <br />
              <b style={{ "font-size": "0.5em" }}>AUTHORIZED</b>
            </div>
          </div>
        </div>
      ) : props.wasWalletEnabled() ? (
        <div className={classes.header}>
          <p>
            <br />
          </p>
        </div>
      ) : (
        <div>
          <ConnectW
            account={props.account}
            network={props.network}
            uriParts={props.uriParts}
            connectMetaMask={props.connectMetaMask}
            connectWalletConnect={props.connectWalletConnect}
          />
        </div>
      )}

      <div className={classes.info} width="200">
        <div></div>
        <p>
          <br />
        </p>

        {props.digged ? (
          <div>
            <p>Invite your Friends</p>
            <ul>
              <li>
                <br /> invite uri:
                <br />
                <b>
                  <a href={props.uriInvite}>{uriInviteDotted}</a>
                </b>{" "}
                &nbsp;
                <img
                  alt="Copy"
                  width="15px"
                  src={copyicon}
                  onClick={copyAddress}
                />
                {copiedInfo ? "Copied" : null}
              </li>
              <li>
                <br /> invite full uri:
                <br />
                <b>{props.uriInvite}</b>{" "}
                <b>
                  <a href={props.uriInvite} style={{ color: "white" }}>
                    Go
                  </a>
                </b>
                &nbsp;
                <br />
              </li>
              <li>
                <br />

                <QRCode
                  _value={uriInviteDeepLink}
                  value={props.uriInvite}
                  eyeRadius={6}
                  qrStyle="dots"
                  __qrStyle="squares"
                  ecLevel="H"
                  size="250"
                  fgColor="rgb(253, 85, 36)"
                  _fgColor="rgb(135, 238, 251)"
                  bgColor="rgb(0 0 0)"
                  logoImage={z.icon128}
                  logoWidth="50"
                  removeQrCodeBehindLogo={true}
                />
              </li>
              <li>
                <br />
                Invite your friends, allow them to dig {z.title} too. Their mobile
                should handle this code. For every {z.title} your friend may dig,
                you'll get some reward.
              </li>
              <li>Android Users should enable</li>
              <li>
                <i>
                  Apps*Standard&nbsp;Apps*Links*MetaMask*Addresses*metamask.app.link
                </i>
              </li>
              <li>in Settings before.</li>
            </ul>
            <p>
              <br />
            </p>
          </div>
        ) : props.friendExists ? (
          <div>
            <p>YOU NEED TO DIG SOME {z.title} FIRST</p>
            <ul>
              <li> switch to the Dig Tab and dig some {z.title}!</li>
            </ul>
            <p>
              <br />
            </p>
          </div>
        ) : (
          <div>
            <p>YOU NEED AN INVITATION</p>
            <ul>
              <li>
                {" "}
                ask your friends around the world to be invited. Join and Earn!
              </li>
            </ul>
            <p>
              <br />
            </p>
          </div>
        )}
        <p>
          <br />
        </p>
      </div>
      <p>
        <br />
        <br />
        <br />
      </p>
      <div className={classes.dbgText}>
        <button
          onClick={() => {
            props.setOfflineOverride(true);
          }}
        >
          Order
        </button>
        {props.offlineOverride}
      </div>
      <br />
      <br />
      {window.alternate ? (
        ""
      ) : (
        <div className={classes.dbgText}>
          <button
            onClick={() => {
              window.alternate = true;
            }}
          >
            Alternate
          </button>
          {props.offlineOverride}
        </div>
      )}
      <br />
      <br />
    </div>
  );
};

export default Profile;

/*
&nbsp;
<button
className={classes.connectButton}
  onClick={() => {
    props.connectWalletConnect();
  }}>
  Walletconnect
</button>

                    <br/> invite deep uri:
                    <br/><b>{uriInviteDeepLink}</b> <b><a href={uriInviteDeepLink}  style={{  color: "white"}}>Go</a></b>
                        &nbsp;
                    <br/>

*/
