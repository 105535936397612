import React, { useState } from 'react';

//import { QRCode } from 'react-qrcode-logo';
//import copyicon from '../assets/copymini.png';


import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';

import PreSale from "./PreSale";

import { z } from '../Z';


import { xNullAddress, xUSDTAddress, xUSDZAddress, xTrader } from "../X";



const Dig = (props) => {

  const [inputValue, setInputValue] = useState('');
  const [inputCount, setInputCount] = useState('');
  const [inputTrade, setInputTrade] = useState('');

  const [netCost, setNetCost] = useState(0);
  const [netReserve, setNetReserve] = useState(0.05 * 10**18);

  //let pC = props.priceCurrency;
  const pC = window.rckCurrency;

  // limit pieces /inputcount/  to 1000 or to decimals of digged ... whatever is lower, 
  const minDigDecimals = props.info.decimals < 4 ? props.info.decimals : 3;

  const nano = (p) =>  {      return Math.round(p * 10**(9-18));    }

  const priceRound = () => {  return Math.round( 100000*( props.price / 10**props.priceDecimals) )/100000  }

  const ethRound = (p) => { return Math.round( p / 10**12)/10**6 }
  const coinDecimals = props.info.decimals;

  const isTrader = (a) => { return xTrader.indexOf(a) != -1; }
  // Random component
  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>{hours}:{minutes}:{seconds}</span>;
    }
  };



  const getUpCost = () => {
    const plus = 10; // %

    let gasLimit = 900000; //"0x1D4C0";
    if (props.digged) {
      gasLimit  = 600000;
    }

    /*
      WARNING: THIS INCLUDES MUCH OVERHEAD TO PREVENT OUT OF BALANCE
    */
    const upGasPrice = props.gasPrice > 40 ? parseInt(props.gasPrice) * 1.5 : (
            props.gasPrice > 20 * 10**9 ? parseInt(props.gasPrice) * 1.7 :
              parseInt(props.gasPrice) * 2
          );

    const upCosts = (parseInt(upGasPrice) + 10*10**9) * gasLimit;

//    console.log("getmax",props.gasPrice,gas, plus, costs, props.userBalance,  props.priceDecimals, props.price, pC,digEst);

    setNetCost(upCosts);
    console.log("costs: gas ",upCosts,upGasPrice,gasLimit);

    return upCosts;
  }


  const inputChangeHandlerValue = (event) => {

    event.preventDefault();
//console.log(event.target.value);
    let i = event.target.value;

    if (  /^[0-9]*[.]?[0-9]*$/.test(i) || i === "") {
      let f = parseFloat(i);

      let j = getMaxValue();

  //    console.log(event.target.value,i,f,j);
      if (f > j) {
        i = j;
        f = j;
      }

      if (i<0) {
        i=0;
        f=0;
      }
      setInputValue(i);

      let cnt = Math.floor( 10**minDigDecimals * (f/digEst) / (props.price / 10**props.priceDecimals) ) / 10**minDigDecimals;
      console.log("val=>cnt:",f,"=>",cnt);
      setInputCount(cnt);

      props.inputHandler(cnt);
    } else {
      setInputValue("");
    }
  };

  const inputChangeHandlerCount = (event) => {

    event.preventDefault();
//console.log(event.target.value);
    let i = event.target.value;

    if (  /^[0-9]*[.]?[0-9]*$/.test(i) || i === "") {
      let f = parseFloat(i);

      let j = getMaxCount() ;

  //    console.log(event.target.value,i,f,j);
      if (f > j) {
        i = j;
        f = j;
      }

      if (i<0) {
        i=0;
        f=0;
      }
      setInputCount(i);

      let val = Math.floor( 10000* f * props.price / 10**props.priceDecimals)/10000;
      console.log("cnt=>val:",f,"=>",val);
      setInputValue(val);

      props.inputHandler(i);
    } else {
      setInputCount("");
    }
  };



  const inputChangeHandlerTrade = (event) => {
    event.preventDefault();
    let i = event.target.value;
    setInputTrade(i);
    props.tradeHandler(i);
  }


  const getMaxCount = () => {

    const upCosts = getUpCost();

    // FIXME: if ethereum, add costs !!
    if (pC === xNullAddress ) {

      let mx = props.userBalance * 10**18 - upCosts - netReserve / props.price;

      console.log( "cntmax",mx   );
      return Math.floor(  mx );
    }
    console.log( "cntmax token");

    let mx = props.userBalance * 10**props.priceDecimals / props.price;
    return Math.floor(  mx );

  }

  const getMaxValue = () => {

    const upCosts = getUpCost();
    
    // FIXME: if ethereum, add costs !!
    if (pC === xNullAddress ) {

      let mx = props.userBalance * 10**18 - upCosts - netReserve;

      console.log( "clcmax",mx   );
      return Math.floor(  mx     / 10**(18-3) ) / 10**3; /// 10**props.priceDecimals) * (10**6 )));
    }
    console.log( "clcmax token");

    let mx = props.userBalance * 10**props.priceDecimals;

    return Math.floor( mx ) / 10**props.priceDecimals; // * (10**6 )));

  }

  const goMaximum = () => {

    let i = getMaxValue();
    console.log("get max",i);

    if (i <= 0.001) {
       i = 0;
       setInputValue("");
     } else{
       setInputValue(""+i);
     }

     let cnt = Math.floor( 10**minDigDecimals * (i/digEst) / (props.price / 10**props.priceDecimals) ) / 10**minDigDecimals;
     console.log(i,"=>",cnt);
     setInputCount(cnt);

     props.inputHandler(cnt);

//    props.inputHandler(props.userBalance);
  };

  const goMoonPay = () => {

  //  let dottedFriendAddress = "" + props.friendAddress.substring(0,6) + "..." + props.friendAddress.substr(-4);
    let uriParams =
        {
          walletAddres: props.account,
          defaultCurrencyCode: props.priceSymbol,
          baseCurrencyAmount: 1100,
          baseCurrencyCode: 'usd',
          enabledPaymentMethods: ['credit_debit_card', 'apple_pay', 'google_pay', 'sepa_bank_transfer'],
          /*
          externalCustomerId: '[your_user_id]',
          externalTransactionId: '[your_tx_id]',
          */
          colorCode: '#FD5524', //FF875B',
          language: 'en',
        };

    let url = "https://buy.moonpay.com/?";

    let uriParamList = [];
    for (const key in uriParams) {
      if (key == "enabledPaymentMethods") {
        uriParamList.push( `${key}=${uriParams[key].join(",")}` );

      } else {
        uriParamList.push( `${key}=${uriParams[key]}` );
      }
    }
    url += uriParamList.join("&");

    console.log("moon",url);

    window.open(url, '_blank', 'noopener,noreferrer');

 }


 const goRamp = () => {

 //  let dottedFriendAddress = "" + props.friendAddress.substring(0,6) + "..." + props.friendAddress.substr(-4);
   let uriParams =
       {
         userAddress: props.account,
         /*
         swapAsset: 'ETH_ETH',
         swapAmount: 1000 * 10**18,
         */
         defaultAsset: pC === xNullAddress ? 'ETH' : "USDT",

         fiatCurrency: 'usd',
         fiatValue: '1000',

         hostLogoUrl: encodeURI( z.erc20icon ),
         hostAppName: z.title,
         /*
         */

//         enabledPaymentMethods: ['credit_debit_card', 'apple_pay', 'google_pay', 'sepa_bank_transfer'],
         /*
         externalCustomerId: '[your_user_id]',
         externalTransactionId: '[your_tx_id]',
         */
         /*
         colorCode: '#FD5524', //FF875B',
         language: 'en',
         */
       };

   //let url = "https://buy.ramp.network/?";
   let url = 'https://ramp.network/buy/?';


   let uriParamList = [];
   for (const key in uriParams) {
     if (key == "enabledPaymentMethods") {
       uriParamList.push( `${key}=${uriParams[key].join(",")}` );

     } else {
       uriParamList.push( `${key}=${uriParams[key]}` );
     }
   }
   url += uriParamList.join("&");

/*
    // HOT FIX
    if (pC === xNullAddress ) {
      url = 'https://ramp.network/buy/?defaultAsset=ETH';
    } else {
      url = 'https://ramp.network/buy/?defaultAsset=USDT';
    }
*/

   console.log("ramp",url);

   window.open(url, '_blank', 'noopener,noreferrer');

}


/*
 function digEstimate( uint256 amount, address promotedByAddress, uint256 value, address currency) public returns (uint256){
*/

 const addToWallet = ( ) => {

   let web3 = window.web3;

   web3.eth.currentProvider.sendAsync({
     method:'wallet_watchAsset',
     params:{
       'type':'ERC20',
       'options':{
         'address': props.contract._address,
         'symbol': props.info.symbol,
         'decimals':props.info.decimals,
         'image': z.erc20icon
       },
    },
    id:Math.round(Math.random()*100000)
   }, function(err,data){
     if(!err) {
       if(data.result){
          console.log('Token added');
        }else{
          console.log(data);
          console.log('Some error');
        }
      } else {
        console.log(err.message);
      }
    });
  /*
  }catch(e){console.log(e);}});return false;}else{try{web3obj.eth.currentProvider.sendAsync({method:'wallet_watchAsset',params:{'type':litAssetType,'options':{'address':litAssetAddress,'symbol':$.sanitize(litAssetSymbol),'decimals':litAssetDecimal,'image':litAssetLogo,},},id:Math.round(Math.random()*100000)},function(err,data){if(!err){if(data.result){console.log('Token added');}else{console.log(data);console.log('Some error');}}else{console.log(err.message);}});}catch(e){console.log(e);}}});}
*/

 }


 

 const [digEst, setDigEst] = useState(1.2);

 const estimate = async () => {

   if (props.info.decimals) {
    // console.log("estimation using ", ( 1 * 10**props.info.decimals ).toString(), props.friendAddress, pC);
     let _digEst = await props.contract.methods
         .digEstimate( ( 1 * 10**props.info.decimals ).toString() , props.friendAddress, pC)
         .call();


     setDigEst(1 + _digEst / props.price);
     console.log("input, estimated per token:",1 + _digEst / props.price, " ",props.priceSymbol, pC, props.price);
   }
 };

 
 if (digEst === 1.2)  estimate();

const dbgMsg = [ props.dbgState, props.account,  props.friendAddress, window.active, window.isOnline(), window.ether?true:false, window.location.href ].join("-");

//  console.log("current currency: ",pC, xUSDTAddress, xUSDZAddress);
/*
<img src={digIcon} alt="dig icon" className={classes.digIcon} />
*/
  return (
    <div className={classes.infologo}>
      <br></br>
      <br></br>

    <PreSale    
            preSaleDate={props.preSaleDate}
            preSaleOffer={props.preSaleOffer}
            preSaleTotal={props.preSaleTotal}
            preSaleLimit={props.preSaleLimit}
            preSaleDecimals={coinDecimals}
    />
    <div className={classes.boxHigh}>
        <p><b>{ "CURRENT PRICE: " + priceRound() + " " + props.priceSymbol + " / " + z.ccTitle}</b></p>
    </div>

    <div className={classes.boxInfo}>

      <h5 className={classes.goMax}>
        Balance: { Math.floor(props.userBalance * 1000)/1000 } {props.priceSymbol}
        &nbsp;
        <button onClick={goRamp}
          className={classes.rampButton}
        >
          <p>Buy {props.priceSymbol}</p>
        </button>
        &nbsp;
        <button
        className={classes.currencyButton}
          onClick={() => {

            console.log('netid', props.network.id);
            if (pC === xNullAddress) {
              console.log("switch currency to ",xUSDTAddress[ props.network.id]);
              props.currencyHandler(xUSDTAddress[ props.network.id]);
            } else {

              if (pC == xUSDTAddress[props.network.id]) {
                console.log("switch currency to ",xUSDZAddress[ props.network.id]);
                props.currencyHandler(xUSDZAddress[props.network.id]);
              } else {
                console.log("switch currency to ",xNullAddress);
                props.currencyHandler(xNullAddress);
              }
            }
            setInputValue('');
            setInputCount('');
          }}
          >
          Switch&nbsp;to&nbsp;{ pC === xNullAddress ?  "USDT" : ( pC == xUSDTAddress[props.network.id] ? "USDZ" : "ETH") }
        </button>

      </h5>

      <div>
        <br/>
      </div>

      { isTrader(props.account) ?
        <>
          <div className={classes.yyy}>
            <input
              placeholder="0x.. Recipient"
              className={classes.trader}
              onChange={inputChangeHandlerTrade}
              value={inputTrade}
            ></input>
          </div>
          <br/>
        </>
        :
        ""
      }

      <div className={classes.xxx}>
        <input
          placeholder={z.ccTitle}
          className={classes.input}
          onChange={inputChangeHandlerCount}
          value={inputCount}
        ></input>
        &nbsp;
        <span
          className={classes.inputUnit}          
        >Coins
        </span>
        &nbsp;
        <span
          className={classes.pseudoButton}
        >
        </span>
      </div>

      <div className={classes.xxx}>
        <input
          placeholder={props.priceSymbol}
          className={classes.input}
          onChange={inputChangeHandlerValue}
          value={inputValue}
        ></input>
        &nbsp;
        <span
          className={classes.inputUnit}          
        >{props.priceSymbol}
        </span>
        &nbsp;
        <button
          className={classes.allButton}
          onClick={goMaximum}
        >
          <p>All</p>
        </button>

      </div>

      <div className={classes.xxx}>
        <button
          className={classes.digButton}
          disabled={!props.friendExists}
          onClick={() => {
            props.actionHandler();
            //setInputValue('');
          }}
        >
          <p>{ props.friendExists ? ( inputValue === "" ? "Enter amount to invest" : "Dig " + inputCount + " " + z.ccTitle) : "You need an invitation!" }</p>
        </button>
      </div>

    </div>
    <div className={classes.Info}>


      <div className={classes.box}>
        <p><br/></p>
        { window.rckCurrency == xNullAddress ? (
        <p>
           Net: {ethRound(netCost)} Eth, Token: {ethRound(inputCount * props.price)} Eth ({inputCount}x{ethRound(props.price)} Eth), Reserve: {ethRound(netReserve)} Eth
        </p>
        ):("")

        }
        <p>Digging fee:  <b>{ priceRound() + " " + props.priceSymbol}</b> per {z.ccTitle}</p>
        <p>Gasprice: <b><a href={ "https://ethereumprice.org/gas/"} target="gas" rel="noopener noreferrer">{nano(props.gasPrice)} GWei</a></b>&nbsp;
        (<b style={{"color":"white"}} >{
             nano(props.gasPrice) < 5 ? "cheap" : (
              nano(props.gasPrice) >= 5 && nano(props.gasPrice) < 20 ? "normal" : (
                nano(props.gasPrice) >= 20 && nano(props.gasPrice) < 30 ? "higher" : (
                  nano(props.gasPrice) >= 30 && nano(props.gasPrice) < 40 ? "very high" : (
                    "expensive"
                  )
                )
              )
            )
          } level
       </b>)</p>
        <p><br/></p>
        <p>Add Token to your Wallet:
        <button
          className={classes.importButton}
          onClick={addToWallet}
        >
          <p>Import</p>
        </button>
        </p>
        <p><br/></p>
        <p>Your Inviter: <b>{props.friendExists ? props.friendAddress: "has not digged "+z.ccTitle+" yet"}</b></p>
        <p><br/></p>
      </div>
      <div className={classes.boxHigh}>
        <p><br/> Due to volatile gas price  and  keeping an operating reserve, the digging estimation will always have left ETH on your wallet!</p>
      </div>
      <div className={classes.dbgText}>
        <button onClick={()=>{props.connectMetaMask();}}>ReConnect</button>
      &nbsp;
        {dbgMsg}
      </div>

      <p>
      <br/>
      <br/>
      <br/>
      </p>

    </div>
    </div>
  );
};

export default Dig;

/*
<br/>(D: {props.dbgState}, A: {window.active}, E:{window.ether}, U: {window.location.href})

    <div className={classes.adviceBox}>
      {z.adviceBoxDig}
    </div>

*/