import React from "react";

import coinAbi from './abis/SQRS.json';
import toolsAbi from './abis/ROCK3TOOLS.json' ;

import coinIcon from './assets/coin/sqrs500.png';
import coinIcon128 from "./assets/coin/sqrs128.png";

import headerIcon from './assets/coin/sqrsheader_white.png'; //128.png';


export const z = {
  title: "Sqares",
  ccTitle: "Sqares CC",
  fullTitle: "",
  coin: "SQRS",

  icon: coinIcon,
  icon128: coinIcon128,
  headerIcon: headerIcon,

  abi: coinAbi,

  erc20icon: "https://sqares-coin.app/info/sqrs500.png",

  stripeURI: "https://buy.stripe.com/9AQeXt4572Iz5568wM",

  portalURI: 'https://sqares.com/',

  paymentURI: "https://sqares.tech/sqarescoinpayment",
  
  formSubmitURI: 'https://sqares-coin.app/form/',

  hint: "",
  _hint: "( 1 Sqare RET represents 1m² = 2.000 Skyland CC)",

  contractMap: {
    1: { shield: coinAbi, tools: toolsAbi,  alternate: coinAbi },
    4: { shield: coinAbi, tools: toolsAbi,  alternate: coinAbi },
    5: { shield: coinAbi, tools: toolsAbi,  alternate: coinAbi },
    11155111: { shield: coinAbi, tools: toolsAbi,  alternate: coinAbi },
  },

  orderForm: {
    coinName: "Sqares CC"
  },

  adviceBoxDig:"",

  _adviceBoxDig:
      <p>If you plan to swap into Skyland Real Estate Tokens later, consider that <br/>
      1 Sqare RET represents 1m² = 2.000 Skyland CC<br/></p>,

}


/*  styles left:

.header {
  background-image: url("../assets/coin/iconHeader.png");
}

.logo {
  background-image: url("../assets/coin/icon1500x.png");
}
*/


